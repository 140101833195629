<template>
	<div class="addCommodity">
		<frame :top1="top1" :top2="top2">
			<!-- <steps  :num='num'></steps> -->
			<ification :num='num' @addone='nextone' @addTow='nexttow' @addThree='oksub' @rollback='rollback'>
				<!-- <div class="n" style="width: 200px;height: 100px;border: 1px solid red;">1111111</div> -->
			</ification>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import steps from './componAdd/Steps.vue';
	import ification from './componAdd/Classification.vue';
	export default {
		name: 'addCommodity',
		data() {
			return {
				top1: '4-6',
				top2: ['4'],
				num: 0,
				ssid:'',
				dataAdd: {
					one: null,
					tow: null,
					three: null
				},
				
			}
		},
		components: {
			frame,
			steps,
			ification
		},
		created() {
			// console.log('shuai',this.$route.query.id)
		},
		methods: {
			nextone(data) {
				console.log('1111',data)
				this.num += 1
				this.dataAdd.one = data.val
				// this.$emit('xstoreid',data.xstoreid)
				// this.ssid=data.id
			},
			nexttow(data) {
				console.log('222',data);
				this.num += 1;
				this.dataAdd.tow = data;
				this.ssid=data.store
			},
			oksub(data) {
				this.dataAdd.three = data.newDatas;
				// console.log(this.dataAdd);
				// return false;
				let lunboImg = []
				for (var i = 0; i < this.dataAdd.tow.fileList.length; i++) {
					lunboImg.push(this.dataAdd.tow.fileList[i].response.data.src);
				}
				let token = this.$storage.getLocal('token');
				let type = this.$storage.getLocal('type');
				
				if(this.$request.getUrl('id')){
					this.$request.goodsUpData({
						token,
						type,
						gtid: this.dataAdd.one,
						gname: this.dataAdd.tow.name,
						gtitle: this.dataAdd.tow.subhead,
						storeid: this.ssid,
						glid: this.dataAdd.tow.label,
						t_id: '',
						selling_price: this.dataAdd.tow.selling,
						original_price: this.dataAdd.tow.market,
						stock: this.dataAdd.tow.inventory,
						s_warning_val: this.dataAdd.tow.early,
						m_id: this.dataAdd.tow.measure,
						g_weight: '',
						is_grounding: this.dataAdd.tow.putaway == true ? 1 : 2,
						gimg: lunboImg,
						gcid: data.model1,
						gcval: data.newDatas,
						gservice: this.dataAdd.tow.service,
						gid: this.$request.getUrl('id')
					}).then(res => {
						if(res.code == 0){
							this.$Message.success('修改商品成功');
							this.$router.push('/commodityList',function(){},function(){});
						}
					})
				}else{
					this.$request.goodsAddData({
						token,
						type,
						gtid: this.dataAdd.one,
						gname: this.dataAdd.tow.name,
						gtitle: this.dataAdd.tow.subhead,
						storeid: this.ssid,
						glid: this.dataAdd.tow.label,
						t_id: '',
						selling_price: this.dataAdd.tow.selling,
						original_price: this.dataAdd.tow.market,
						stock: this.dataAdd.tow.inventory,
						s_warning_val: this.dataAdd.tow.early,
						m_id: this.dataAdd.tow.measure,
						g_weight: '',
						is_grounding: this.dataAdd.tow.putaway == true ? 1 : 2,
						gimg: lunboImg,
						gcid: data.model1,
						gcval: data.newDatas,
						gservice: this.dataAdd.tow.service,
					}).then(res => {
						if(res.code == 0){
							this.$Message.success('添加商品成功');
							this.$router.push('/commodityList',function(){},function(){});
						}
					})
				}
				
				// console.log(data.newDatas);
				// return false
				// console.log(dara);
				
				// this.num += 1;
				
				
			},
			rollback(){
				this.num -= 1;
			}
		}
	} 
</script>

<style scoped="scoped">
	.addCommodity

	{
	background-color: rgb(245,247,249);
	height: 1000px;
	}
</style>
