<template>
	<div>
		<div class="shuxing" >
			属性类型
			<Select v-model="model1" style="width:200px" @on-change='select'>
				<Option v-for="item,index in cityList" :value="item.gcid" :key="item.gcid">{{ item.gcname }}</Option>
			</Select>
		</div>
		<div>
			<table ref='table'>
				<tr>
					<td v-for='item2,index2 in datatype' :key='index2' :name="item2.gsname" :ref='index2'>{{item2.specname}}</td>
					<td>销售价格</td>
					<td>商品库存</td>
					<td>库存预警</td>
					<td>图片</td>
					<td>操作</td>
				</tr>
				<tr v-for="(item, index) in formDynamic.items" v-if="item.status" :key="index" :label="'Item ' + item.index" ref='tr'>

					<td v-for='item2,index2 in datatype' :key='index2' :name="item2.gsname" :ref='index2'>
						<Select v-model="item.select[index2]">
							<Option v-for="item3,index3 in item2.specarr" :value="item3.gsvid" :key="index3">{{ item3.gsvname }}</Option>
						</Select>
						<!-- <div>{{item3.gsvid}}</div> -->
					</td>
					<td><Input type="number" v-model="item.selling_price" placeholder="请输入..."></Input></td>
					<td><Input type="number" v-model="item.stock" placeholder="请输入..."></Input></td>
					<td><Input type="number" v-model="item.s_warning_val" placeholder="请输入..."></Input></td>
					<td class="oper" @click="addimg(index)">
						<el-upload class="avatar-uploader" :action="uploadingUrl2" :show-file-list="false"
						 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="item.imgOne" :src="item.imgOne" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</td>
					<td class="oper"><Button @click="handleRemove(index)" type="error">删除</Button></td>
				</tr>
				<Button type="error" @click="handleAdd" icon="md-add">增加</Button>
			</table>
		</div>
		<Button type="error" ghost @click="rollback()">上一步，填写商品信息</Button>
		<Button type="error" @click="handleSubmit('formDynamic')" plain>完成</Button>
	</div>
</template>

<script>
	export default {
		name: 'three',
		data() {
			return {
				index: 1,
				formDynamic: {
					items: [{
						selling_price: '',
						stock: '',
						s_warning_val: '',
						index: 1,
						status: 1,
						select: {},
						imgOne: '',
						gdsimg: ''
					}]
				},

				imageUrl: '',
				types: [],
				datatype: null,
				cityList: [],
				kind: ['黑色'],
				model1: '',
				leixing: {},
				modal1: false,
				imageUrl: '',
				uploadingUrl1: null,
				uploadingUrl2: null,
				dialogImageUrl: '',
				dialogVisible: false,
				indexs: 1,
				addImages: [],
				addIndex: 0
			}
		},
		created() {
			this.uploadingUrl1 = this.$request.imgUrl2() + 'uploads/';
			this.uploadingUrl2 = this.$request.imgUrl() + 'PHPExcel/fileUpload';

			let token = this.$storage.getLocal('token');
			let type = this.$storage.getLocal('type');
			this.$request.GoodsselGoodsClassData({
				token,
				type
			}).then(res => {
				// var arr = [{}];
				if (res.code == 0) {
					// for (var i = 0; i < res.data.length; i++) {
					// 	arr[i].value = res.data[i].gcid;
					// 	arr[i].label = res.data[i].gcname
					// }
					// this.cityList = arr;
					this.cityList = res.data;
					
				}
				// console.log(res.data)

			})
			// this.
			// this.datatype
			
			
			if (this.$request.getUrl('id')) {
				let id = this.$request.getUrl('id');
				
				this.formDynamic.items.splice(0,1);//如果是修改的话就移除第一条空数据
				
				this.$request.goodsUpInfoData({
					token,
					id
				}).then(res => {
					if (res.code == 0) {
						this.model1 = res.data.info.gcid
						for (var i = 0; i < res.data.spec.length; i++) {
							console.log(res.data.spec[i]);
							this.index++;
							this.formDynamic.items.push({
								index: this.index,
								selling_price: res.data.spec[i].selling_price,
								stock: res.data.spec[i].stock,
								s_warning_val: res.data.spec[i].s_warning_val,
								index: 1,
								status: 1,
								select: res.data.spec[i].specval,
								imgOne: this.uploadingUrl1 + res.data.spec[i].gdsimg,
								gdsimg: res.data.spec[i].gdsimg
							});
							// console.log(list)
						}
						// console.log(this.formDynamic.items)
// this.formDynamic.items[this.addIndex].imgOne = URL.createObjectURL(file.raw);
				// this.formDynamic.items[this.addIndex].gdsimg = file.response.data.src
						// let token = this.$storage.getLocal('token');
						this.$request.selGoodsSpecData({
							token,
							gcid: res.data.info.gcid
						}).then(res => {
							if (res.code == 0) {
								this.datatype = res.data
							}
						})
					}
				})
			}


		},
		methods: {




			handleAvatarSuccess(res, file) {
				// console.log(res,file);
				this.formDynamic.items[this.addIndex].imgOne = URL.createObjectURL(file.raw);
				this.formDynamic.items[this.addIndex].gdsimg = file.response.data.src
				
				// console.log(file.response.data.src);
				
				// console.log(this.formDynamic.items);
				
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},













			rollback() {
				this.$emit('rollback')
			},
			handleSubmit(name) {
				let newDatas = [];
				for (var i = 0; i < this.formDynamic.items.length; i++) {
					if (this.formDynamic.items[i].status == 0) {
						console.log(this.formDynamic.items[i]);
					} else {
						newDatas.push(this.formDynamic.items[i])
					}
				}
				// newDatas[]

				// console.log(newDatas);
				this.$emit('addThree', {
					newDatas,
					model1: this.model1
				});
			},
			handleReset(name) {
				this.$refs[name].resetFields();
			},
			handleAdd() {
				this.index++;
				this.formDynamic.items.push({
					index: this.index,
					selling_price: '',
					stock: '',
					s_warning_val: '',
					index: 1,
					status: 1,
					select: {},
					imgOne: ''
				});
			},
			handleRemove(index) {
				this.formDynamic.items[index].status = 0;
			},



			addimg(index) {
				this.addIndex = index
			},

			ok() {
				this.$Message.info('Clicked ok');
			},
			cancel() {
				this.$Message.info('Clicked cancel');
			},
			generate() {
				this.kind.push('黑色');
			},
			del(index) {
				this.$refs.table.removeChild(this.$refs.tr[index])
			},
			next() {
				console.log(this.$refs.select)
			},
			selectbtn(id) {

			},
			select(gcid) {
				console.log(gcid)
				let token = this.$storage.getLocal('token');
				this.$request.selGoodsSpecData({
					token,
					gcid
				}).then(res => {
					if (res.code == 0) {
						this.datatype = res.data
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	button {
		margin-right: 20px;
	}

	.el-upload-list__item-actions {
		display: none;
	}

	#url {
		border: 1px solid #333333;
		width: 178px;
		margin: 0 auto;
	}


	.shuxing {
		padding-top: 50px;
	}

	.type {
		width: 50%;
		border: 1px solid #FFFFFF;
		margin: 0 auto;
		margin-top: 50px;
		background-color: #FFFFFF;
	}

	.form {
		display: flex;
		justify-content: space-between;
		width: 30%;
		margin: 0 auto;
	}

	.input {
		display: inline-block;
	}

	#content {
		width: 130px;
		text-align: center;
		margin: 0 auto;
	}

	table {
		width: 50%;
		background: #eeeeee;
		margin: 0 auto;
		margin-bottom: 50px;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		/* display: flex; */
		/* justify-content: space-around; */
		color: #42B983;
	}




	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 50px;
		/* width: 178px;
		height: 178px; */
		/* line-height: 178px; */
		text-align: center;
	}

	.avatar {
		width: 50px;
		/* height: 178px; */
		display: block;
	}
</style>
