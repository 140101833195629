<template>
  <div>
    <div class="listcont" v-if="ye !== 1">
      <div class="shuai" v-if="selTypeShow">
        <div class="selname">商家类型:</div>
        <Select placeholder="请选择" class="sel">
          <!-- <Option   value="">全部</Option> -->
          <Option
            :value="item.storeid"
            v-for="(item, index) in selects"
            :key="index"
            @click.native="shopType(item.storeid)"
            >{{ item.s_name }}</Option
          >
        </Select>
      </div>

      <div class="input" v-if="selTypeShow">
        <Button type="error" @click="search(xstoreid)">搜索</Button>
      </div>
    </div>
    <Collapse v-model="value1" style="margin-top: 20px;">
      <Panel :name="index" v-for="(item, index) in lists" :key="index">
        {{ item.t_name }}
        <p slot="content" id="content">
          <RadioGroup
            v-model="phone"
            v-for="(item2, index2) in item.son"
            :key="index2"
          >
            <Radio :label="item2.gtid">
              <span>{{ item2.t_name }}</span>
            </Radio>
          </RadioGroup>
        </p>
      </Panel>
    </Collapse>
    <Button type="error" @click="next()" style="margin-bottom: 20px;">下一步，填写商品信息</Button>
  </div>
</template>

<script>
export default {
  name: "one",
  data() {
    return {
      value1: "",
      ye: "",
      animal: "",
      value: "",
      lists: null,
      phone: "",
      typeFlag: "",
      xstoreid: "",
      selects: "",
      selectp: "",
      selTypeShow: true, //门店权限下选择商家类型的隐藏
    };
  },
  props: {
    datas: {
      type: Array,
    },
  },
  created() {
    // wx.getStorage({
    //   key: "type",
    //   success(res) {
    //     console.log(res.data);
    //   },
    // });

    console.log("shuai", this.$route.query.id);
    let token = this.$storage.getLocal("token");
    this.typeFlag = this.$storage.getLocal("type");
    let yes = this.$route.query.id;
    console.log(this.typeFlag);
    if(this.typeFlag == 3 || this.typeFlag == 2){
      console.log(33333);
      this.selTypeShow = false
    }
    if (yes) {
      this.ye = 1;
      let token = this.$storage.getLocal("token");
      let storeid = this.$route.query.storeid;
      this.$request
        .getUpStoreData({
          token,
          // login_type:this.typeFlag,
          storeid: storeid,
        })
        .then((res) => {
          if (res.code == 0) {
            this.lists = res.data;
            this.$request
              .selGoodsTypeData({
                token,
                login_type: this.typeFlag,
                storeid: res.data.pstoreid,
              })
              .then((res) => {
                if (res.code == 0) {
                  this.lists = res.data;
                }
              });
          }
        });
    } else {
      this.ye = 2;
    }
    // console.log(this.$props.datas)

    if (this.typeFlag == "1") {
      this.$request.getMerchantData({ token }).then((r) => {
        console.log(123);
        if (r.code == 0) {
          this.selects = r.data;
          console.log(r);
        }
      });
    } else if (this.typeFlag == "2") {
      this.$request
        .getStoreDataList({
          token,
          pstoreid: "",
          login_type: this.$storage.getLocal("type"),
        })
        .then((r) => {
          console.log(123);
          if (r.code == 0) {
            this.selectp = r.data;
            console.log(r);
          }
        });
    }
    this.$request
      .selGoodsTypeData({
        token,
        login_type: this.typeFlag,
        storeid: "",
      })
      .then((res) => {
        if (res.code == 0) {
          this.lists = res.data;
        }
      });
    console.log("one");
    // console.log(this.amendData);
    // this.phone = this.amendData.gtid
    // console.log(this.$store.commit("goodsAmend"));
    // console.log(GOODSAMEND);

    // console.log(this.$store.state.goodsAmend);
    // let token = this.$storage.getLocal('token');
    if (this.$request.getUrl("id")) {
      let id = this.$request.getUrl("id");
      this.$request.goodsUpInfoData({ token, id }).then((res) => {
        if (res.code == 0) {
          this.phone = res.data.info.gtid;
        }
      });
    }
  },
  methods: {
    next() {
      // console.log(this.selectp	)
      // if (this.phone) {
        this.$emit("addone", {
          val: this.phone,
          xstoreid: this.selectp,
          id: this.xstoreid,
        });
        // eventBus.$emit('xstoreid',this.xstoreid)
        // console.log(this.$emit('addone', this.phone))
      // } else {
        // this.$notify({
        //   title: "失败",
        //   message: "请选择分类",
        //   type: "warning",
        // });
      // }
    },
    shopType(value) {
      this.xstoreid = value;
      // this.$storage.setLocal(value,'xstoreid')
      console.log(111);
      let token = this.$storage.getLocal("token");
      this.$request
        .getStoreDataList({
          token,
          pstoreid: value,
          login_type: this.$storage.getLocal("type"),
        })
        .then((r) => {
          console.log(123);
          if (r.code == 0) {
            this.selectp = r.data;
            console.log(r);
          }
        });
    },
    search(value) {
      let token = this.$storage.getLocal("token");
      this.$request
        .selGoodsTypeData({
          token,
          login_type: this.typeFlag,
          storeid: value,
        })
        .then((res) => {
          if (res.code == 0) {
            this.lists = res.data;
          }
        });
    },
    select() {
      console.log(this.value);
    },
  },
};
</script>

<style scoped="scoped">
#content {
  width: 130px;
  text-align: center;
  margin: 0 auto;
}
.ivu-radio {
  text-align: left;
}
.input
{
	margin-left: 50px;
	margin-top: -5px;
}
.ivu-collapse {
  margin-bottom: 50px;
}
.listcont {
  display: flex;
  margin-left: 50px;
}
.shuai {
  display: flex;
  /* border:1px solid black */
}
.selname {
  width: 90%;
}
.sel {
  margin-left: 10px;
  margin-top: -5px;
}
</style>
