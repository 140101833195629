<template>
	<div class="classification">
		<!-- <div class="n" style="width: 100%;height: 100px;border: 1px solid red;">
			<steps :num='0'></steps>
		</div> -->
		<div class="steps">
			<el-steps :active="num" align-center=true finish-status="success"  style="margin-top: 20px;margin-left: 4%;" space="32%">
				<el-step title="选择商品分类" class="one"></el-step>
				<el-step title="填写商品信息" class="two"></el-step>
				<el-step title="填写商品属性" class="three"></el-step>
			</el-steps>
			<div>
				<!-- <img class="imgJt" src="../../../../public/jt.png" /> -->
			</div>
		</div>
		<div class="form" v-show="num==0">
			
			<formsOne @addone='addone'></formsOne>
		</div>
		<div v-show="num==1">
			<formsTow @addTow='addTow' :xstoreid='xstoreid'	  @rollback='rollback'></formsTow>
		</div>
		<div v-show="num==2">
			<formsThree @addThree='addThree' @rollback='rollback'></formsThree>
		</div>
	</div>
	
</template>

<script>
	import steps from './Steps.vue';
	import formsThree from './FromThree.vue'
	import formsTow from './FromTow.vue';
	import formsOne from './FromOne.vue';
	import {GOODSAMEND} from '../../../store/modules/mutations-type.js';
	export default {
		name: 'classification',
		data() {
			return {
				xstoreid:''
			}
		},
		props:{
			num: {
				type: Number
			}
		},
		components:{
			formsTow,
			formsOne,
			formsThree
		},
		created(){
			// console.log('1');
			
		},
		methods:{
			addone(data){
				// console.log('3333',data)
				this.xstoreid=data.xstoreid
				this.$emit('addone',data)
			},
			addTow(data){
				console.log(data)
				this.$emit('addTow',data)
			},
			addThree(data){
				this.$emit('addThree',data)
			},
			rollback(){
				this.$emit('rollback');
			}
		}

	}
</script>

<style scoped="scoped">
	.classification {
		/* padding-left: 200px; */
		width: 82.5%;
		margin-left: 15.5%;
		margin-top: 40px;
		background-color: white;
	}

	.ivu-row {
		width: 80%;
		margin: 0 auto;
	}

	.form {
		width: 100%;
		padding-top: 50px;
		margin: 0 auto;
	}
	.steps {
		padding-top: 5px;
	}
	.el-step{
		width: 300px;
	}
	.one{
		/* border: 1px solid red; */
		height: 50px;
		color: white;
		line-height: 50px;
	}
	.two,
	.three
	{
		background-image: url(../../../../public/jt.png);
		background-repeat: no-repeat;
		background-size:350px  50px;
		
	}
	/deep/ .el-step__title.is-process
	{
		background-image: url(../../../../public/gt.png);
		background-repeat: no-repeat;
		background-size:350px  50px;
		height: 50px;
		color: white;
		line-height: 50px;
		
	}
	/deep/ .el-step__title.is-success
	{
		background-image: url(../../../../public/gt.png);
		background-repeat: no-repeat;
		background-size:350px  50px;
		height: 50px;
		color: white;
		line-height: 50px;
	}
	/deep/ .el-step.is-center .el-step__line{
		display: none;
	}
	/deep/ .el-step__icon.is-text{
		display: none;
	}
</style>
