<template>
  <Form
    ref="formValidate"
    :model="formValidate"
    :rules="ruleValidate"
    :label-width="80"
  >
    <div class="biaodan">
      <!-- <div>{{xstoreid}}</div> -->
      <FormItem label="商品名称" prop="name">
        <Input v-model="formValidate.name" placeholder="请输入名称"></Input>
      </FormItem>
      <FormItem label="副标题" prop="subhead">
        <Input
          v-model="formValidate.subhead"
          placeholder="请输入副标题"
        ></Input>
      </FormItem>
      <FormItem label="所属店铺" prop="store" v-if="showType != 3">
        <Select v-model="formValidate.store" placeholder="请选择">
          <Option
            v-for="(item, index) in selectp"
            :key="index"
            :value="item.storeid"
            @click.native="shopType(item.storeid)"
            >{{ item.s_name }}</Option
          >
        </Select>
      </FormItem>
      <!-- <FormItem label="商品标签">
        <RadioGroup v-model="formValidate.label">
          <Radio :label="item.glid" v-for="(item, index) in lists" :key="index">
            <span>{{ item.glname }}</span>
          </Radio>
        </RadioGroup>
      </FormItem> -->
      <FormItem label="商品售价" prop="selling">
        <Input
          v-model="formValidate.selling"
          type="number"
          placeholder="请输入售价"
        ></Input>
      </FormItem>
      <FormItem label="市场价">
        <Input
          v-model="formValidate.market"
          type="number"
          placeholder="请输入市场价"
        ></Input>
      </FormItem>
      <FormItem label="库存" prop="inventory">
        <Input
          v-model="formValidate.inventory"
          type="number"
          placeholder="请输入库存"
        ></Input>
      </FormItem>
      <FormItem label="预警值">
        <Input
          v-model="formValidate.early"
          type="number"
          placeholder="请输入预警值"
        ></Input>
      </FormItem>
      <!-- <FormItem label="计量单位">
        <Select v-model="formValidate.measure" placeholder="请选择">
          <Option
            v-for="(item, index) in measurelists"
            :key="index"
            :value="item.gmid"
            >{{ item.gmcode }}</Option
          >
        </Select>
      </FormItem> -->
      <FormItem label="商品上架">
        <el-switch
          v-model="formValidate.putaway"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </FormItem>
      <FormItem label="服务保证">
        <CheckboxGroup v-model="formValidate.service">
          <Checkbox label="1">
            <span>无忧退货</span>
          </Checkbox>
          <Checkbox label="2">
            <span>快速退款</span>
          </Checkbox>
          <Checkbox label="3">
            <span>免费包邮</span>
          </Checkbox>
        </CheckboxGroup>
      </FormItem>
    </div>

    <!-- :on-remove="handleRemove2" -->
    <!-- <el-upload ref='lone' :action="uploadingUrl2" list-type="picture-card" :on-preview="handlePictureCardPreview"
		 :on-remove="handleRemove2" :on-success='handleRemove' :limit='5'>

			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog> -->

    <el-upload
      class="upload-demo"
      :action="uploadingUrl2"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :file-list="formValidate.fileList"
      list-type="picture"
      :on-success="okUploding"
    >
      <el-button size="small" type="danger">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/png文件，且不超过500kb
      </div>
    </el-upload>

    <FormItem>
      <Button type="error" ghost @click="rollback()" plain
        >上一步，选择商品分类</Button
      >
      <Button type="error" @click="handleSubmit('formValidate')"
        >下一步，填写商品类型</Button
      >
      <Button @click="handleReset('formValidate')" style="margin-left: 8px"
        >清除</Button
      >
    </FormItem>
  </Form>
</template>
<script>
export default {
  name: "tow",
  data() {
    return {
      formValidate: {
        name: "",
        subhead: "",
        store: "",
        label: "",
        freight: "",
        selling: "",
        market: "",
        inventory: "",
        ssid: "",
        early: "",
        putaway: true,
        service: [],
        imgLists: null,
        measure: "",
        fileList: [],
      },
      ruleValidate: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        subhead: [
          {
            required: true,
            message: "请输入副标题",
            trigger: "blur",
          },
        ],
        store: [
          {
            transform: (value) => String(value),

            required: true,
            message: "请选择店铺",
            trigger: "change",
          },
        ],
        freight: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
        selling: [
          {
            required: true,
            message: "请输入售价",
            trigger: "blur",
          },
        ],
        inventory: [
          {
            required: true,
            message: "请输入库存",
            trigger: "blur",
          },
        ],
      },
      showType: false,
      lists: null,
      dialogImageUrl: "",
      dialogVisible: false,
      length: null,
      uploadingUrl1: null,
      uploadingUrl2: null,
      measurelists: null,
    };
  },
  props: {
    amendData: {
      type: Object,
    },
    xstoreid: {
      type: Array,
    },
  },
  created() {
    console.log("55555", this.$props);
    this.uploadingUrl1 = this.$request.imgUrl2() + "uploads/";
    this.uploadingUrl2 = this.$request.imgUrl() + "PHPExcel/fileUpload";

    this.showType = this.$storage.getLocal("type");
    let token = this.$storage.getLocal("token");
    let type = this.$storage.getLocal("type");
    this.$request
      .getStoreDataList({
        token,
        pstoreid: this.$props.xstoreid,
        login_type: this.$storage.getLocal("type"),
      })
      .then((r) => {
        console.log(123);
        if (r.code == 0) {
          this.selectp = r.data;
          console.log(r);
        }
      });
    this.$request
      .labelListData({
        token,
        type,
        storeid: "",
      })
      .then((res) => {
        this.lists = res.data;
      });

    this.$request
      .meteringListData({
        token,
        id: type,
        storeid: "",
      })
      .then((res) => {
        //计量单位列表
        if (res.code == 0) {
          this.measurelists = res.data;
        }
      });

    if (this.$request.getUrl("id")) {
      let id = this.$request.getUrl("id");
      this.$request
        .goodsUpInfoData({
          token,
          id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.formValidate.store = res.data.info.storeid;

            this.formValidate.name = res.data.info.gname;
            this.formValidate.subhead = res.data.info.gtitle;
            this.formValidate.label = res.data.info.glid;
            this.formValidate.selling = res.data.info.selling_price;
            this.formValidate.market = res.data.info.original_price;
            this.formValidate.inventory = res.data.info.stock.toString();
            this.formValidate.early = res.data.info.s_warning_val;
            this.formValidate.measure = res.data.info.m_id;
            this.formValidate.putaway =
              res.data.info.is_grounding == 1 ? true : false;
            this.formValidate.service = res.data.info.gservice;
            for (var i = 0; i < res.data.info.gimg.length; i++) {
              var arr = {
                name: res.data.info.gimg[i],
                url: this.uploadingUrl1 + res.data.info.gimg[i],
                response: {
                  data: {
                    src: res.data.info.gimg[i],
                  },
                },
              };
              this.formValidate.fileList.push(arr);
            }
          }
        });

      // res.data.info.gimg

      // this.fileList.push()
      // this.formValidate.imgLists.push

      // gimg
    }
  },
  methods: {
    okUploding(response, file, fileList) {
      // console.log(response, file, fileList);
      this.formValidate.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.formValidate.fileList = fileList;
      // console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    shopType(value) {
      let token = this.$storage.getLocal("token");
      this.formValidate.ssid = value;
      let type = this.$storage.getLocal("type");
      this.$request
        .labelListData({
          token,
          type,
          storeid: value,
        })
        .then((res) => {
          this.lists = res.data;
        });

      this.$request
        .meteringListData({
          token,
          id: type,
          storeid: value,
        })
        .then((res) => {
          //计量单位列表
          if (res.code == 0) {
            this.measurelists = res.data;
          }
        });
    },

    rollback() {
      this.$emit("rollback");
    },
    handleSubmit(name) {
      console.log(name);
      console.log(this.formValidate.store);
      this.$refs[name].validate((valid) => {
        if (valid) {
          console.log(this.formValidate);
          this.$emit("addTow", this.formValidate);
        } else {
          this.$Message.error("请输入！！！");
        }
      });
    },
    // handleReset(name) {
    // 	this.$refs[name].resetFields();
    // },
    // handleRemove(response, file, fileList) {
    // 	// console.log(fileList);
    // 	// this.$refs.lone.$children[1].$el.style.display = 'block'
    // 	// console.log(fileList);
    // 	this.formValidate.imgLists = fileList;
    // 	console.log(fileList);
    // 	// if (this.length = fileList.length == 5) {//如果图片上传五个后隐藏加号
    // 	// 	// console.log(this.$refs.lone.$children[1].$el)
    // 	// 	this.$refs.lone.$children[1].$el.style.display = 'none';
    // 	// }
    // 	// console.log(fileList);

    // },
    // handleRemove2(file, fileList) {

    // 	this.$refs.lone.$children[1].$el.style.display = 'inline-block'
    // },
    // handlePictureCardPreview(file) {
    // 	// console.log(file.url);
    // 	this.dialogImageUrl = file.url;
    // 	// console.log(file)

    // 	// console.log(file[0].url)

    // 	this.dialogVisible = true;
    // }
  },
};
</script>
<style scoped="scoped">
.ivu-form {
  width: 100%;
  margin: 0 auto;
  padding-top: 80px;
}

.biaodan {
  width: 40%;
  margin: 0 auto;
}

button {
  margin-right: 20px;
}

.ivu-form-item {
  margin-top: 50px;
}
</style>
