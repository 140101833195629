<template>
	<div class="steps">
		<el-steps :active="num" align-center=true finish-status="success"  style="margin-top: 20px;margin-left: 25%;	" space="27%"  >
			<el-step title="选择商品分类" class="one"></el-step>
			<el-step title="填写商品信息" class="two"></el-step>
			<el-step title="填写商品属性" class="three"></el-step>
		</el-steps>
		<div>
			<!-- <img class="imgJt" src="../../../../public/jt.png" /> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'steps',
		data() {
			return {

			}
		},
		props: {
			num: {
				type: Number
			}
		},
		components: {

		},
		created() {

		},
		methods: {

		}
	}
</script>

<style scoped="scoped">
	.steps {
		/* padding-left: 300px; */
	}
	.el-step{
		width: 300px;
	}
	.one{
		/* border: 1px solid red; */
		height: 50px;
		color: white;
		line-height: 50px;
	}
	.two,
	.three
	{
		background-image: url(../../../../public/jt.png);
		background-repeat: no-repeat;
		background-size:300px  50px;
		
	}
	/deep/ .el-step__title.is-process
	{
		background-image: url(../../../../public/gt.png);
		background-repeat: no-repeat;
		background-size:300px  50px;
		height: 50px;
		color: white;
		line-height: 50px;
		
	}
	/deep/ .el-step__title.is-success
	{
		background-image: url(../../../../public/gt.png);
		background-repeat: no-repeat;
		background-size:300px  50px;
		height: 50px;
		color: white;
		line-height: 50px;
	}
	/deep/ .el-step.is-center .el-step__line{
		display: none;
	}
	/deep/ .el-step__icon.is-text{
		display: none;
	}
	/* /deep/ .el-step__title.is-wait{
		height: 80px;
		background-image: url(../../../../public/bck.png);
	} */
</style>
